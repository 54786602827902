import { useState } from "react";
import Sidebar from "../components/Sidebar";
import Toggle from "../components/Toggle";
import Tile from "../components/Tile";
import { DARK, LIGHT } from "../config/constants";
import { themeChanger } from "../config/theme";
import { data } from "../service/json";

import { FaCloudDownloadAlt ,FaLinkedin,FaEnvelope,FaGithub} from "react-icons/fa";

const Home = () => {
  const [theme, setTheme] = useState(DARK);
  const onThemClick = () => {
    if (theme === LIGHT) {
      setTheme(DARK);
    } else {
      setTheme(LIGHT);
    }
  };
  return (
    <div className={`sm:flex ${themeChanger(theme).background} `}>
      <Sidebar theme={theme} />
      <div className="m-6">
        <div className="w-full">
          <div
            className={`${themeChanger(theme).divColor} m-6 mt-10 p-6 relative`}
          >
            <div
              className={`absolute top-0 right-0 p-1 px-2 m-1 cursor-pointer`}
            >
              <Toggle theme={theme} onThemClick={onThemClick} />
            </div>

            <div
              className={`${
                themeChanger(theme).primaryText
              } text-[30px] sm:text-[50px] font-bold`}
            >
              Hello, I'm {data.name}
            </div>
            <div className={`${themeChanger(theme).primaryText} mt-2`}>
              Front End developer from India with industry experience building
              web apps. I specialise in TypeScript, JavaScript and have
              professional experience working with React. I share what I learn
              through blog. I am short film director come actor and you can find
              my movies here.
            </div>
            <div
              className={`border ${themeChanger(theme).border} ${
                themeChanger(theme).primaryText
              } flex justify-center items-center w-52 h-10 font-bold text-sm mt-5 rounded-sm cursor-pointer`}
            >
             <FaCloudDownloadAlt color={`${themeChanger(theme).purecolor}`} size={30} className="mr-2"/>  DOWNLOAD RESUME
            </div>
          </div>

          <div
            className={`sm:mx-6 px-6 mb-3 sm:flex items-center justify-between ${
              themeChanger(theme).secondaryText
            } `}
          >
            <div className="flex items-center justify-between my-1">
              <div
                className={`${
                  themeChanger(theme).primaryText
                } font-bold text-[25px]`}
              >
                {data.carrierAchivements.yearOfExperience}
              </div>
              <div className="ml-2">Years Experience</div>
            </div>
            <div className="flex items-center justify-between my-1">
              <div
                className={`${
                  themeChanger(theme).primaryText
                } font-bold text-[25px]`}
              >
                {data.carrierAchivements.completedProjects}
              </div>
              <div className="ml-2">Completed Projects</div>
            </div>
          </div>
          <div className="px-6">
            <div
              className={`text-lg font-bold ${themeChanger(theme).primaryText}`}
            >
              
              TECHS I USE{" "}
            </div>
            <div className="flex flex-wrap items-center">
              {data.techonologies.map((item, index) => {
                return (
                  <div
                    className={`${
                      themeChanger(theme).divColor
                    } flex flex-col justify-center items-center m-1 w-32 h-32 p-2`}
                  >
                    <img
                      src={item.logo}
                      height={60}
                      width={60}
                      className="rounded-full"
                    />
                    <div className={`${themeChanger(theme).primaryText}`}>
                      {item.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        
          <div className="ml-6 mt-4">
            <div
              className={`text-lg font-bold ${themeChanger(theme).primaryText}`}
            >
              COMPANIES AND EXPERIENCE
            </div>
            <div className="flex flex-wrap mt-2">
              {data.companyandExperience.map((item, index) => {
                return (
                  <div
                    className={`${
                      themeChanger(theme).divColor
                    } p-5 mr-2 w-72 mb-2`}
                  >
                    <div className="flex flex-col items-center">
                      <img
                        src={item.logo}
                        height={100}
                        width={100}
                        className="rounded-full"
                      />
                      <div className={`${themeChanger(theme).primaryText}`}>
                        {item.name}
                      </div>
                      <div className={`${themeChanger(theme).primaryText}`}>
                        {item.years}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div
              className={` ml-6  mt-4 text-lg font-bold ${
                themeChanger(theme).primaryText
              }`}
            >
              COMPANY PROJECTS
            </div>
            <div className="sm:flex items-center justify-between mx-4 h-100 ">
              {data.projects.map((item, index) => {
                return (
                  <div
                    className={`${themeChanger(theme).divColor} h-100 m-2 p-6`}
                  >
                    <div
                      className={`${
                        themeChanger(theme).primaryText
                      } mb-1 font-bold`}
                    >
                      {item.projectname}
                    </div>
                    <div className="text-[gray] text-sm">
                      {item.description}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div
              className={` ml-6 sm:px-6 mt-4 text-lg font-bold ${
                themeChanger(theme).primaryText
              }`}
            >
              PERSONAL PROJECTS
            </div>
            <div className="sm:flex items-center justify-between mx-4 h-100 ">
              {data.projects.map((item, index) => {
                return (
                  <div
                    className={`${themeChanger(theme).divColor} h-100 m-2 p-6`}
                  >
                    <div
                      className={`${
                        themeChanger(theme).primaryText
                      } mb-1 font-bold`}
                    >
                      {item.projectname}
                    </div>
                    <div className="text-[gray] text-sm">
                      {item.description}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className={`px-6 m-6 ${themeChanger(theme).divColor}`}>
            <div className="flex justify-around item-center">
              <Tile item={"Gmail"} theme={theme} icon={<FaEnvelope/>}/>
              <Tile item={"LinkDin"} theme={theme} icon={<FaLinkedin/>} />
              <Tile item={"Github"} theme={theme} icon={<FaGithub/>} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
