import React, { useState } from "react";
import { LIGHT } from "../config/constants";

const Toggle = (props: any) => {
  return (
    <div
      className={`bg-white w-14 flex cursor-pointer border-2 rounded-full`}
      onClick={() => props.onThemClick()}
    >
      <div
        className={` ${
          props.theme === LIGHT ? "bg-black" : "bg-white"
        } w-1/2 h-6 transition ease-in-out delay-150 rounded-full`}
      ></div>
      <div
        className={` ${
          props.theme === LIGHT ? "bg-white" : "bg-black"
        } w-1/2 h-6 transition ease-in-out delay-150 rounded-full`}
      ></div>
    </div>
  );
};

export default Toggle;
