import React from 'react'
import { themeChanger } from '../config/theme'

type tileProps ={
    index?:number,
    theme:string,
    item:string,
    icon?:any
   
}

const Tile = (props:tileProps) => {
  return (
    <div
    key={props.index}
    className={`border ${themeChanger(props.theme).border} ${
      themeChanger(props.theme).primaryText
    } flex justify-center items-center py-2 px-4 font-bold text-sm my-3 mr-4 rounded-sm`}
  >
   <div className='mr-2'> {props.icon}</div>
    {props.item}
  </div>
  )
}

export default Tile