import React from "react";
import photo from "../assets/user.jpg";
import { themeChanger } from "../config/theme";
import { data } from "../service/json";
import Tile from "./Tile";

const Sidebar = (props: any) => {
  return (
    <div className={`${themeChanger(props.theme).divColor} xl:w-1/4 px-6 py-2`}>
      <div
        className={`${
          themeChanger(props.theme).secondaryDiv
        } flex flex-col justify-center items-center h-56`}
      >
        <img src={photo} height={100} width={100} className="rounded-full" />
        <div
          className={`${
            themeChanger(props.theme).primaryText
          } font-medium text-lg`}
        >
          {data.name}
        </div>
        <div className="text-slate-500 font-medium text-sm">
          Front-end Developer
        </div>
        <div className="text-slate-500 font-medium text-sm">UI Designer</div>
      </div>
      <div
        className={`m-4 ${themeChanger(props.theme).secondaryText} font-normal`}
      >
        <div className="flex items-center mb-2">
          <div className="font-bold w-1/2">Residence</div>
          <div className="w-1/2">{data.residence}</div>
        </div>
        <div className="flex items-center mb-2 ">
          <div className="font-bold w-1/2">City</div>
          <div className="w-1/2">{data.city}</div>
        </div>
        <div className="flex items-center mb-2">
          <div className="font-bold w-1/2">Contact</div>
          <div className="w-1/2">+91 {data.phone}</div>
        </div>
      </div>

      <div
        className={`border-t-2  ${
          themeChanger(props.theme).secondaryText
        } border-[gray] mx-2`}
      >
        <div className="flex justify-between m-4">
          <div className="flex flex-col items-center justify-center">
            <div
              className={`flex justify-center items-center text-sm text-[grey] border-4 ${
                themeChanger(props.theme).border
              } rounded-full w-16 h-16 m-2`}
            >
              <div>100%</div>
            </div>
            <div className="text-sm">Kannada</div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div
              className={`flex justify-center items-center text-sm text-[grey] border-4 ${
                themeChanger(props.theme).border
              } rounded-full w-16 h-16 m-2`}
            >
              <div>70%</div>
            </div>
            <div className="text-sm">English</div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div
              className={`flex justify-center items-center text-sm text-[grey] border-4 ${
                themeChanger(props.theme).border
              } rounded-full w-16 h-16 m-2`}
            >
              <div>60%</div>
            </div>
            <div className="text-sm">Hindi</div>
          </div>
        </div>
      </div>
      <div></div>
      <div
        className={`border-t-2  ${
          themeChanger(props.theme).secondaryText
        } border-[gray] mx-2`}
      >
        <div
          className={`mt-2 text-lg font-bold ${
            themeChanger(props.theme).primaryText
          }`}
        >
          HOBBY
        </div>
        <div className="flex flex-wrap">
          {data.hobby.map((item, index) => (
            <Tile item={item} index={index} theme={props.theme} />
          ))}
        </div>
      </div>
      <div></div>
      <div
        className={`border-t-2  ${
          themeChanger(props.theme).secondaryText
        } border-[gray]`}
      >
        <div
          className={`mt-2 text-lg font-bold ${
            themeChanger(props.theme).primaryText
          }`}
        >
          TOOLS WHICH I KNOW
        </div>
        <div className="flex flex-col items-center">
          {data.tools.map((item, index) => {
            return (
              <div
                className={` ${themeChanger(props.theme).background} 
                flex flex-col justify-center items-center m-1 w-40 h-40 p-2 mt-5`}
              >
                <img
                  src={item.logo}
                  height={60}
                  width={60}
                  className="rounded-full"
                />
                <div className={`${themeChanger(props.theme).primaryText}`}>
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
