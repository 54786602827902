import {
  excelsoft,
  designurway,
  reactjs,
  angularjs,
  javascript,
  typescript,
  html,
  css,
  bootstrap,
  tailwind,
  microsoft,
  photoshop,
  premiumpro,
} from "../assets";

export const data = {
  name: "Sunil Kumar",
  phone: 9742302309,
  email: "sunilcharan333@gmail.com",
  linkDin: "",
  designation: "Software Engineer",
  residence: "Vijaynagar",
  city: "Chamarajanagar",
  age: 29,
  carrierAchivements: {
    yearOfExperience: 2.5,
    completedProjects: 2,
    honorAndAwards: 2,
  },
  techonologies: [
    {
      name: "React JS",
      logo: reactjs,
    },
    {
      name: "Angular JS",
      logo: angularjs,
    },
    {
      name: "React - Native",
      logo: reactjs,
    },
    {
      name: "Javascript",
      logo: javascript,
    },
    {
      name: "TypeScripit",
      logo: typescript,
    },
    {
      name: "HTML",
      logo: html,
    },
    {
      name: "CSS",
      logo: css,
    },
    {
      name: "Bootstrap",
      logo: bootstrap,
    },
    {
      name: "Tailwind CSS",
      logo: tailwind,
    },
  ],
  tools: [
    { name: "Adobe Photoshop", logo: photoshop },
    {
      name: "Adobe Premire Pro",
      logo: premiumpro,
    },
    {
      name: "Microsoft Office",
      logo: microsoft,
    },
  ],
  hobby: [
    "Drama",
    "Painting",
    "Video Editing",
    "Movie Editing",
    "Acting",
    "Cooking",
    "Cricket",
    "Volleyball",
  ],
  projects: [
    {
      projectname: "Imagine Learning",
      description:
        " A paragraph is defined as a group of sentences or a single    sentence that forms a unit",
    },
    {
      projectname: "Idly Dosey",
      description:
        " A paragraph is defined as a group of sentences or a single    sentence that forms a unit",
    },
  ],
  companyandExperience: [
    {
      name: "ExcelSoft Technologies pvt ltd.",
      logo: excelsoft,
      years: "1+ years",
    },
    {
      name: "Designurway Technologies pvt ltd.",
      logo: designurway,
      years: "1 years",
    },
  ],
};
